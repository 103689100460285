import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { InboxResponse } from '../../../core/api/api';
import { inboxManagerResetUnreadedMessageBegin } from '../store/actions/inbox-manager.action';
import { selectInboxManagerApiResponseState } from '../store/selectors/inbox-manager.selectors';

@Injectable({
  providedIn: 'root',
})
export class InboxManagerService {
  readonly inbox$: Observable<InboxResponse | null>;
  readonly unreadedMessages$: Observable<boolean>;

  constructor(private store: Store) {
    this.inbox$ = this.store.select(selectInboxManagerApiResponseState);
    this.unreadedMessages$ = this.inbox$.pipe(map((inbox) => (inbox ? inbox.unreadedMessages > 0 : false)));
  }

  resetUnreadedMessages() {
    this.store.dispatch(inboxManagerResetUnreadedMessageBegin());
  }
}
