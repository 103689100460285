import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { NavigationService } from './navigation/services/navigation.service';
import { tokenLoadLocalStore } from './shared/store/actions/token.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;

  isNavigationOpen$: Observable<boolean>;
  isMobile = false;

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private router: Router,
    private platform: Platform,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.store.dispatch(tokenLoadLocalStore());
    this.isNavigationOpen$ = this.navigationService.isNavigationOpen$;
    this.isMobile = this.platform.IOS || this.platform.ANDROID;
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: any) => {
      this.scrollContainer.nativeElement.scrollTo(0, 0);
    });

    if (!this.isMobile) {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((result) => {
        this.isMobile = result.matches;
        this.changeDetectorRef.markForCheck();
      });
    }
  }
}
