import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() isMobile = false;

  @Input()
  public get isOpen(): boolean | null {
    return this._isOpen;
  }
  public set isOpen(value: boolean | null) {
    this._isOpen = value;
  }

  private _isOpen: boolean | null = false;

  constructor() {}

  ngOnInit(): void {}
}
