import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'influencer',
    loadChildren: () => import('./influencer/influencer.module').then((m) => m.InfluencerModule),
  },
  {
    path: 'owner',
    loadChildren: () => import('./owner/owner.module').then((m) => m.OwnerModule),
  },
  {
    path: 'media',
    outlet: 'modal',
    loadChildren: () => import('./media/media.module').then((m) => m.MediaModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
