import { createReducer, on } from '@ngrx/store';
import { InboxResponse } from '../../../../core/api/api';
import {
  inboxManagerLoadInboxSucceed,
  inboxManagerResetUnreadedMessageSuccess,
  inboxManagerUnset,
} from '../actions/inbox-manager.action';

export const INBOX_MANAGER_STORE_KEY = 'inboxManager';

export interface InboxManagerState {
  readonly api_repsonse: InboxResponse | null;
}

export const initialState: InboxManagerState = {
  api_repsonse: null,
};

export const inboxManagerReducer = createReducer(
  initialState,
  on(inboxManagerUnset, (): InboxManagerState => initialState),
  on(
    inboxManagerLoadInboxSucceed,
    (state, action): InboxManagerState => ({ ...state, api_repsonse: action.payload.inbox }),
  ),
  on(
    inboxManagerResetUnreadedMessageSuccess,
    (state, action): InboxManagerState => ({ ...state, api_repsonse: action.payload.inbox }),
  ),
);
