import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../environments/environment';
import { dealReducer, DealState } from './shared/store/reducers/deal.reducer';
import { imageReducer, ImagesState } from './shared/store/reducers/image.reducer';
import { inboxManagerReducer, InboxManagerState } from './shared/store/reducers/inbox-manager.reducer';
import { mediaManagerReducer, MediaManagerState } from './shared/store/reducers/media-manager.reducer';
import { spotReducer, SpotState } from './shared/store/reducers/spot.reducer';
import { tokenReducer, TokenState } from './shared/store/reducers/token.reducer';
import { userReducer, UserState } from './shared/store/reducers/user.reducer';

export interface AppState {
  token: TokenState;
  user: UserState;
  mediaManager: MediaManagerState;
  images: ImagesState;
  spot: SpotState;
  deal: DealState;
  inboxManager: InboxManagerState;
}

export const reducers: ActionReducerMap<AppState> = {
  token: tokenReducer,
  user: userReducer,
  mediaManager: mediaManagerReducer,
  images: imageReducer,
  spot: spotReducer,
  deal: dealReducer,
  inboxManager: inboxManagerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
