import { createReducer, on } from '@ngrx/store';
import {
  navigationHistoryAdd,
  navigationHistoryPop,
  navigationReset,
  navigationSetOpen,
  navigationSetRootNavigation,
} from '../actions/navigation.actions';

export const NAVIGATION_STORE_KEY = 'navigation';

export interface NavigationState {
  isOpen: boolean;
  rootNavigation: string[];
  navigationHistory: string[];
}

export const initialState: NavigationState = {
  isOpen: false,
  rootNavigation: ['/'],
  navigationHistory: [],
};

export const navigationReducer = createReducer(
  initialState,
  on(navigationReset, () => initialState),
  on(navigationSetOpen, (state, { payload: { isOpen } }) => ({
    ...state,
    isOpen,
  })),
  on(navigationSetRootNavigation, (state, { payload: { rootNavigation } }) => ({
    ...state,
    rootNavigation,
  })),
  on(navigationHistoryAdd, (state, action) => ({
    ...state,
    navigationHistory: [...state.navigationHistory, action.payload.history],
  })),
  on(navigationHistoryPop, (state, action) => ({
    ...state,
    navigationHistory: [...state.navigationHistory.slice(0, -1)],
  })),
);
