import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DealCreateRequestDto, DealResponse } from '../../../../core/api/api';
import { PaginatedResponse } from '../../models/paginated.interface';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class DealConnector {
  dealEndpoint: string;

  constructor(configService: ConfigService, private httpClient: HttpClient) {
    this.dealEndpoint = `${configService.endpoint}/deal`;
  }

  createDeal(spotId: string, dealCreateDto: DealCreateRequestDto): Observable<DealResponse> {
    return this.httpClient.post<DealResponse>(`${this.dealEndpoint}/spot/${spotId}`, dealCreateDto);
  }

  deactivateDeal(dealId: string): Observable<DealResponse> {
    return this.httpClient.get<DealResponse>(`${this.dealEndpoint}/deactivate/${dealId}`);
  }

  loadDeal(dealId: string): Observable<DealResponse> {
    return this.httpClient.get<DealResponse>(`${this.dealEndpoint}/${dealId}`);
  }

  loadExpiredDealsOfSpot(spotId: string): Observable<PaginatedResponse<DealResponse>> {
    return this.httpClient.get<PaginatedResponse<DealResponse>>(`${this.dealEndpoint}/spot/${spotId}/expired`);
  }

  loadAllDeals(): Observable<PaginatedResponse<DealResponse>> {
    return this.httpClient.get<PaginatedResponse<DealResponse>>(`${this.dealEndpoint}`);
  }
}
