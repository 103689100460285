<sl-header [isOpen]="isOpen$ | async" [isMobile]="isMobile"></sl-header>

<ng-container *ngIf="!isMobile; else mobileNavigation">
  <sl-main-navigation *ngIf="isOpen$ | async">
    <sl-navigation-menu *ngIf="userLoggedIn$ | async"> </sl-navigation-menu>
  </sl-main-navigation>
</ng-container>

<ng-template #mobileNavigation>
  <sl-tab-navigation></sl-tab-navigation>
</ng-template>

<sl-footer [showFooter]="isNotOpen$ | async"></sl-footer>
