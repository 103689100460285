import { Portal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserState } from '../../../shared/store/reducers/user.reducer';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'sl-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent {
  @Input() isMobile = false;

  user$: Observable<UserState>;
  userLoggedIn$: Observable<boolean>;

  isOpen$: Observable<boolean>;
  isNotOpen$: Observable<boolean>;

  navigationProfileActionsPortal$: Observable<Portal<any> | null>;

  constructor(private navigationService: NavigationService) {
    this.user$ = this.navigationService.user$;
    this.userLoggedIn$ = this.navigationService.userLoggedIn$;

    this.isOpen$ = this.navigationService.isNavigationOpen$;
    this.isNotOpen$ = this.isOpen$.pipe(map((isOpen) => !isOpen));

    this.navigationProfileActionsPortal$ = this.navigationService.navigationProfileActionsPortal$;
  }

  logOut() {
    this.navigationService.logout();
  }
}
