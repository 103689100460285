import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { tokenUnset } from '../../../shared/store/actions/token.actions';
import { NavigationService } from '../../services/navigation.service';
import {
  navigationHistoryAdd,
  navigationHistoryGoBack,
  navigationHistoryPop,
  navigationReset,
} from '../actions/navigation.actions';

@Injectable()
export class NavigationEffects {
  resetNavigationOnLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenUnset),
      map(() => navigationReset()),
    ),
  );

  storeLocationHistory$ = createEffect(() =>
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      withLatestFrom(this.navigationService.navigationHistory$),
      filter(([event, history]) => history[history.length - 1] !== (event as NavigationEnd).urlAfterRedirects),
      map(([event, history]) =>
        navigationHistoryAdd({ payload: { history: (event as NavigationEnd).urlAfterRedirects } }),
      ),
    ),
  );

  historyGoBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navigationHistoryGoBack),
      withLatestFrom(this.navigationService.navigationHistory$),
      map(() => {
        this.location.back();
        return navigationHistoryPop();
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private navigationService: NavigationService,
    private location: Location,
  ) {}
}
