import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';
import { NavigationService } from '../../../navigation/services/navigation.service';
import { tokenExpired, tokenUnset } from '../actions/token.actions';

const ignoreRoutes = [
  '/auth/activate/',
  '/auth/sign-up',
  '/auth/reset-password',
  '/auth/reset-password-succeed',
  '/auth/new-password',
  '/auth/sign-up-succeed',
  '/auth/active-succeed',
];
const ignoreRoutesRegex = new RegExp(ignoreRoutes.join('|'), 'i');

@Injectable()
export class TokenRedirectEffects {
  userRedirectOnExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tokenExpired, tokenUnset),
        map(() => this.window.location.href),
        filter((url) => !ignoreRoutesRegex.test(url)),
        tap(() => this.navigationService.setRootNavigation(['/'])),
        tap(() => this.router.navigate(['auth/login'])),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    @Inject(Window) private window: Window,
    private navigationService: NavigationService,
  ) {}
}
