import { Portal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'sl-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NavigationMenuComponent implements OnInit {
  navigationProfileActionsPortal$: Observable<Portal<any> | null>;

  constructor(private navigationService: NavigationService) {
    this.navigationProfileActionsPortal$ = this.navigationService.navigationProfileActionsPortal$;
  }

  ngOnInit(): void {}

  logOut() {
    this.navigationService.logout();
  }
}
