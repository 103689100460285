import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationEffects } from './store/effects/notification.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, NotificationRoutingModule, SharedModule, EffectsModule.forFeature([NotificationEffects])],
})
export class NotificationModule {}
