import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentModule } from '../components/shared-component.module';
import { SharedMaterialModule } from '../modules/shared-material.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { DealCardDetailComponent } from './deal-card-detail/deal-card-detail.component';
import { DealCardComponent } from './deal-card/deal-card.component';
import { FavoritButtonComponent } from './favorit-button/favorit-button.component';
import { FileSelectorComponent } from './file-selector/file-selector.component';
import { FileStoreComponent } from './file-store/file-store.component';
import { SpotCardDetailComponent } from './spot-card-detail/spot-card-detail.component';

@NgModule({
  imports: [CommonModule, SharedMaterialModule, RouterModule, SharedComponentModule, SharedPipesModule],
  declarations: [
    FileSelectorComponent,
    FileStoreComponent,
    SpotCardDetailComponent,
    DealCardDetailComponent,
    DealCardComponent,
    FavoritButtonComponent,
  ],
  exports: [
    FileSelectorComponent,
    FileStoreComponent,
    SpotCardDetailComponent,
    DealCardDetailComponent,
    DealCardComponent,
    FavoritButtonComponent,
  ],
})
export class SharedCommonModule {}
