import { createAction, props } from '@ngrx/store';
import { Action } from '../../../shared/store/actions/base.action';

export const navigationReset = createAction('[navigation] reset to initial state');
export const navigationSetOpen = createAction('[navigation] set open State', props<Action<{ isOpen: boolean }>>());
export const navigationSetRootNavigation = createAction(
  '[navigation] set root navigation',
  props<Action<{ rootNavigation: string[] }>>(),
);

export const navigationHistoryAdd = createAction('[navigation] add to history', props<Action<{ history: string }>>());
export const navigationHistoryPop = createAction('[navigation] remove last from history');
export const navigationHistoryGoBack = createAction('[navigation] history go back');
