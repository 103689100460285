import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { DealService } from '../../shared/services/deal.service';
import { SpotService } from '../../shared/services/spot.service';
import { tokenUnset } from '../../shared/store/actions/token.actions';
import { UserState } from '../../shared/store/reducers/user.reducer';
import { selectUserId, selectUserState } from '../../shared/store/selectors/user.selectors';
import {
  navigationHistoryGoBack,
  navigationSetOpen,
  navigationSetRootNavigation,
} from '../store/actions/navigation.actions';
import {
  selectNavigationHistory,
  selectNavigationIsOpen,
  selectNavigationRootNavigation,
} from '../store/selectors/influencer.selectors';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  readonly user$: Observable<UserState>;
  readonly userLoggedIn$: Observable<boolean>;
  readonly isNavigationOpen$: Observable<boolean>;
  readonly rooteNavigation$: Observable<string[]>;
  readonly navigationHistory$: Observable<string[]>;
  readonly navigationPortal$: Observable<Portal<any> | null>;
  readonly navigationMobilePortal$: Observable<Portal<any> | null>;
  readonly navigationProfileActionsPortal$: Observable<Portal<any> | null>;
  readonly navigationMobileSelectedIndex$: Observable<number>;
  readonly title$: Observable<string | null>;

  private _navigationPortal$ = new BehaviorSubject<Portal<any> | null>(null);
  private _navigationMobilePortal$ = new BehaviorSubject<Portal<any> | null>(null);
  private _navigationProfileActionsPortal$ = new BehaviorSubject<Portal<any> | null>(null);
  private _navigationMobileSelectedIndex$ = new BehaviorSubject<number>(0);
  private _title$ = new BehaviorSubject<string | null>(null);

  constructor(private store: Store, private spotService: SpotService, private dealService: DealService) {
    this.user$ = this.store.select(selectUserState);
    this.rooteNavigation$ = this.store.select(selectNavigationRootNavigation);
    this.navigationHistory$ = this.store.select(selectNavigationHistory);
    const userId$ = this.store.select(selectUserId);
    this.userLoggedIn$ = userId$.pipe(map((userId) => !!userId));
    this.isNavigationOpen$ = this.store.select(selectNavigationIsOpen).pipe(debounceTime(20), shareReplay(1));
    this.navigationPortal$ = this._navigationPortal$;
    this.navigationMobilePortal$ = this._navigationMobilePortal$;
    this.navigationMobileSelectedIndex$ = this._navigationMobileSelectedIndex$;
    this.navigationProfileActionsPortal$ = this._navigationProfileActionsPortal$;

    this.title$ = combineLatest([this._title$, this.spotService.selectedSpot$, this.dealService.selectedDeal$]).pipe(
      map(([title, spot, deal]) => {
        if (title) {
          return title;
        }
        if (deal) {
          return deal.title;
        }
        if (spot) {
          return spot.title;
        }
        return null;
      }),
    );
  }

  logout() {
    this.store.dispatch(tokenUnset());
  }

  setRootNavigation(rootNavigation: string[]) {
    this.store.dispatch(navigationSetRootNavigation({ payload: { rootNavigation } }));
  }

  setIsOpen(isOpen: boolean) {
    this.store.dispatch(navigationSetOpen({ payload: { isOpen } }));
  }

  setNavigationPortal(portal: Portal<any> | null) {
    this._navigationPortal$.next(portal);
  }

  setNavigationMobilePortal(portal: Portal<any> | null) {
    this._navigationMobilePortal$.next(portal);
  }

  setNavigationProfileActionsPortal(portal: Portal<any> | null) {
    this._navigationProfileActionsPortal$.next(portal);
  }

  goBack() {
    this.store.dispatch(navigationHistoryGoBack());
  }
}
