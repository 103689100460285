import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { TokenService } from '../../services/token.service';
import { tokenExpired, tokenLoadLocalStore, tokenSet, tokenUnset, tokenValid } from '../actions/token.actions';
import { userSet, userUnset } from '../actions/user.actions';
import { UserState } from '../reducers/user.reducer';

@Injectable()
export class TokenEffects {
  loadToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenLoadLocalStore),
      map(() =>
        this.tokenService.isTokenExpired()
          ? tokenExpired()
          : tokenValid({ payload: { accessToken: this.tokenService.loadToken()! } }),
      ),
    ),
  );

  storeToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tokenSet),
        tap(({ payload: { accessToken } }) => {
          this.tokenService.storeToken(accessToken);
        }),
      ),
    { dispatch: false },
  );

  unsetStoredToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tokenUnset),
        tap(() => this.tokenService.removeToken()),
      ),
    { dispatch: false },
  );

  userLoadDataFromToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenValid, tokenSet),
      map(() => userSet({ payload: { user: this.tokenService.decodeToken()! as UserState } })),
    ),
  );

  userUnsetOnTokenUnset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenUnset, tokenExpired),
      map(() => userUnset()),
    ),
  );

  constructor(private actions$: Actions, private tokenService: TokenService) {}
}
