<nav class="d-flex flex-column w-100 h-100 mw-100 mh-100">
  <div class="w-100 py-2 px-4">
    <a [routerLink]="rooteNavigation$ | async" class="d-block"><img src="assets/spotliked-logo.svg" /></a>
  </div>
  <div class="side-navigation-container flex-grow-1 pt-4">
    <ng-template [cdkPortalOutlet]="navigationPortal$ | async"></ng-template>
  </div>

  <footer>
    <ng-content></ng-content>
  </footer>
</nav>
