import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { DealConnector } from '../../services/connectors/deal.connector';
import { dealLoadDealFailed, dealLoadDealSucceed, dealSetSelectedDeal } from '../actions/deal.action';

@Injectable()
export class DealEffects {
  loadDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealSetSelectedDeal),
      exhaustMap((action) =>
        this.dealConnector.loadDeal(action.payload.dealId).pipe(
          map((deal) => dealLoadDealSucceed({ payload: { deal } })),
          catchError(({ error }) => of(dealLoadDealFailed({ payload: { error } }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private dealConnector: DealConnector) {}
}
