import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedMaterialModule } from '../modules/shared-material.module';
import { AddressComponent } from './address/address.component';
import { CardComponent } from './card/card.component';
import { CenteredBoxComponent } from './centered-box/centered-box.component';
import { ChipListComponent } from './chip-list/chip-list.component';
import { CountdownComponent } from './countdown/countdown.component';
import { FormCardComponent } from './form-card/form-card.component';
import { GalleryComponent } from './gallery/gallery.component';
import { IconChipComponent } from './icon-chip/icon-chip.component';
import { IconInputComponent } from './icon-input/icon-input.component';
import { ImageComponent } from './image/image.component';
import { InputComponent } from './input/input.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SelectComponent } from './select/select.component';
import { SpotCardComponent } from './spot-card/spot-card.component';
import { SpotSummeryComponent } from './spot-summery/spot-summery.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { TicketSummeryComponent } from './ticket-summery/ticket-summery.component';
import { TypeChipComponent } from './type-chip/type-chip.component';

@NgModule({
  imports: [CommonModule, SharedMaterialModule, FormsModule],
  declarations: [
    CardComponent,
    SpotCardComponent,
    AddressComponent,
    ImageComponent,
    IconChipComponent,
    TypeChipComponent,
    InputComponent,
    IconInputComponent,
    FormCardComponent,
    CenteredBoxComponent,
    SelectComponent,
    TextareaComponent,
    ChipListComponent,
    GalleryComponent,
    SpotSummeryComponent,
    TicketSummeryComponent,
    TicketDetailComponent,
    PaginationComponent,
    NavigationMenuComponent,
    CountdownComponent,
  ],
  exports: [
    CardComponent,
    SpotCardComponent,
    AddressComponent,
    ImageComponent,
    IconChipComponent,
    TypeChipComponent,
    InputComponent,
    IconInputComponent,
    FormCardComponent,
    CenteredBoxComponent,
    SelectComponent,
    TextareaComponent,
    ChipListComponent,
    GalleryComponent,
    SpotSummeryComponent,
    TicketSummeryComponent,
    TicketDetailComponent,
    PaginationComponent,
    CountdownComponent,
  ],
})
export class SharedComponentModule {}
