import { createReducer, on } from '@ngrx/store';
import { SpotResponse } from '../../../../core/api/api';
import { spotLoadSpotSucceed, spotSetSelectedSpot, spotUnsetSelectedSpot } from '../actions/spot.action';

export const SPOT_STORE_KEY = 'spot';

export interface SpotState {
  readonly selectedSpotId: string | null;
  readonly selectedSpot: SpotResponse | null;
}

export const initialState: SpotState = {
  selectedSpotId: null,
  selectedSpot: null,
};

export const spotReducer = createReducer(
  initialState,
  on(spotSetSelectedSpot, (state, action): SpotState => ({ ...state, selectedSpotId: action.payload.spotId })),
  on(spotUnsetSelectedSpot, (state, action): SpotState => ({ ...state, selectedSpotId: null, selectedSpot: null })),
  on(spotLoadSpotSucceed, (state, action): SpotState => ({ ...state, selectedSpot: action.payload.spot })),
);
