import { createReducer, on } from '@ngrx/store';
import { DealResponse } from '../../../../core/api/api';
import { dealLoadDealSucceed, dealSetSelectedDeal, dealUnsetSelectedDeal } from '../actions/deal.action';

export const DEAL_STORE_KEY = 'deal';

export interface DealState {
  readonly selectedDealId: string | null;
  readonly selectedDeal: DealResponse | null;
}

export const initialState: DealState = {
  selectedDealId: null,
  selectedDeal: null,
};

export const dealReducer = createReducer(
  initialState,
  on(dealSetSelectedDeal, (state, action): DealState => ({ ...state, selectedDealId: action.payload.dealId })),
  on(dealUnsetSelectedDeal, (state, action): DealState => ({ ...state, selectedDealId: null, selectedDeal: null })),
  on(dealLoadDealSucceed, (state, action): DealState => ({ ...state, selectedDeal: action.payload.deal })),
);
