import { Inject, Injectable, InjectionToken } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from '../store/reducers/user.reducer';
import { selectUserState } from '../store/selectors/user.selectors';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage,
});

const STORE_TOKEN_KEY = 'access_token';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  public readonly user$: Observable<UserState>;

  constructor(
    private store: Store,
    @Inject(BROWSER_STORAGE) public storage: Storage,
    public jwtHelper: JwtHelperService,
  ) {
    this.user$ = this.store.select(selectUserState);
  }

  loadToken() {
    return this.storage.getItem(STORE_TOKEN_KEY);
  }

  storeToken(token: string) {
    return this.storage.setItem(STORE_TOKEN_KEY, token);
  }

  removeToken() {
    return this.storage.removeItem(STORE_TOKEN_KEY);
  }

  decodeToken(): Promise<UserState | null> | UserState | null {
    return this.jwtHelper.decodeToken();
  }

  isTokenExpired() {
    return this.jwtHelper.isTokenExpired();
  }
}
