<nav class="w-100 px-2 py-2">
  <ul class="user-side-navigation m-0 p-0">
    <ng-template [cdkPortalOutlet]="navigationProfileActionsPortal$ | async"></ng-template>
    <li>
      <div class="p-3 px-4" role="button" (click)="logOut()">Logout</div>
    </li>
    <!-- <li>
      <a class="d-inline-block" routerLink="">
        <div class="p-3 px-4" role="button">Bearbeiten</div>
      </a>
    </li> -->
    <li>
      <div class="d-flex align-items-center p-3">
        <mat-icon>person</mat-icon>
        <span class="ms-1">Profil</span>
        <mat-icon>arrow_drop_up</mat-icon>
      </div>
    </li>
  </ul>
</nav>
