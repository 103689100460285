import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MediaRoutingService } from '../../services/media-routing.service';
import { mediaManagerDialogClose, mediaManagerDialogOpen } from '../actions/media-manager.actions';

@Injectable()
export class MediaRoutingEffects {
  mediaOpenDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mediaManagerDialogOpen),
        tap(({ payload: { spotId } }) =>
          spotId ? this.mediaRoutingService.openSpotMedia(spotId) : this.mediaRoutingService.openMedia(),
        ),
      ),
    { dispatch: false },
  );

  mediaCloseDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mediaManagerDialogClose),
        tap(() => this.mediaRoutingService.closeMedia()),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private mediaRoutingService: MediaRoutingService) {}
}
