import { createAction, props } from '@ngrx/store';
import { MediaFileDto } from '../../../../core/api/api';
import { MediaDialogOption } from '../../models/media-dialog-option.interface';
import { Action } from './base.action';

export const mediaManagerDialogOpen = createAction('[media-manager] open Dialog', props<Action<{ spotId?: string }>>());
export const mediaManagerDialogClose = createAction('[media-manager] close Dialog');

export const mediaManagerStoreReset = createAction('[media-manager] store reset');
export const mediaManagerStoreOpen = createAction(
  '[media-manager] store Is Dialog Open',
  props<Action<{ isOpen: boolean }>>(),
);
export const mediaManagerStoreSpotId = createAction(
  '[media-manager] store Spot Id',
  props<Action<{ spotId: string | null }>>(),
);
export const mediaManagerStorePreSelectedFiles = createAction(
  '[media-manager] store pre selected Files',
  props<Action<{ preSelectedFiles: { id: string }[] }>>(),
);
export const mediaManagerStorePostSelectedFiles = createAction(
  '[media-manager] store post selected Files',
  props<Action<{ postSelectedFiles: MediaFileDto[] }>>(),
);
export const mediaManagerStoreDissmissed = createAction(
  '[media-manager] store dissmissed',
  props<Action<{ dissmissed: boolean }>>(),
);
export const mediaManagerStoreOptions = createAction(
  '[media-manager] store option',
  props<Action<{ option: MediaDialogOption }>>(),
);
