import { createReducer, on } from '@ngrx/store';
import { ImageBucket } from '../../../shared/models/image-bucket.type';
import {
  mediaLoadSucceed,
  mediaRemoveSelectedFile,
  mediaSetImageBucket,
  mediaSetSelectedFiles,
  mediaStoreReset,
} from '../actions/media.actions';

export const MEDIA_STORE_KEY = 'media';

export interface MediaState {
  readonly files: { id: string }[];
  readonly count: number;
  readonly selectedFiles: { id: string }[];
  readonly imageBucket: ImageBucket;
}

export const initialState: MediaState = {
  files: [],
  count: 0,
  selectedFiles: [],
  imageBucket: ImageBucket.deal_gallery,
};

export const mediaReducer = createReducer(
  initialState,
  on(mediaStoreReset, (): MediaState => initialState),
  on(mediaLoadSucceed, (state, { payload: { data, count } }): MediaState => ({ ...state, files: data, count })),
  on(
    mediaSetSelectedFiles,
    (state, { payload: { selectedFiles } }): MediaState => ({
      ...state,
      selectedFiles: selectedFiles,
    }),
  ),
  on(
    mediaRemoveSelectedFile,
    (state, { payload: { selectedFile } }): MediaState => ({
      ...state,
      selectedFiles: state.selectedFiles.filter((file) => file.id !== selectedFile.id),
    }),
  ),
  on(
    mediaSetImageBucket,
    (state, { payload: { imageBucket } }): MediaState => ({
      ...state,
      imageBucket,
    }),
  ),
);
