import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaManagerState, MEDIA_MANAGER_STORE_KEY } from '../reducers/media-manager.reducer';

export const selectMediaManagerState = createFeatureSelector<MediaManagerState>(MEDIA_MANAGER_STORE_KEY);
export const selectMediaManagerStateIsOpen = createSelector(selectMediaManagerState, (state) => state.isOpen);
export const selectMediaManagerStateSpotId = createSelector(selectMediaManagerState, (state) => state.spotId);
export const selectMediaManagerStateOption = createSelector(selectMediaManagerState, (state) => state.option);
export const selectMediaManagerStateDissmissed = createSelector(selectMediaManagerState, (state) => state.dissmissed);
export const selectMediaManagerStatePreSelectedFiles = createSelector(
  selectMediaManagerState,
  (state) => state.preSelectedFiles,
);
export const selectMediaManagerStatePostSelectedFiles = createSelector(
  selectMediaManagerState,
  (state) => state.postSelectedFiles,
);
