import { NgModule } from '@angular/core';
import { SharedComponentModule } from './components/shared-component.module';
import { SharedCommonModule } from './containers/shared-common.module';
import { SharedMaterialModule } from './modules/shared-material.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';

@NgModule({
  imports: [SharedMaterialModule, SharedComponentModule, SharedCommonModule, SharedPipesModule],
  exports: [SharedMaterialModule, SharedComponentModule, SharedCommonModule, SharedPipesModule],
})
export class SharedModule {}
