import { createAction, props } from '@ngrx/store';
import { FavoriteAddRequestDto, FavoriteRemoveRequestDto, FavoriteResponse } from '../../../../core/api/api';
import { Action } from './base.action';

export const favoriteAddBegin = createAction(
  '[favorite] add favorite trigger',
  props<Action<{ favoriteAddDto: FavoriteAddRequestDto }>>(),
);
export const favoriteAddSucceed = createAction(
  '[favorite] add favorite succeed',
  props<Action<{ api_response: FavoriteResponse }>>(),
);
export const favoriteAddFailed = createAction('[favorite] add favorite failed', props<Action<{ error: Error }>>());

export const favoriteRemoveBegin = createAction(
  '[favorite] remove favorite trigger',
  props<Action<{ favoriteRemoveDto: FavoriteRemoveRequestDto }>>(),
);
export const favoriteRemoveSucceed = createAction('[favorite] remove favorite succeed');
export const favoriteRemoveFailed = createAction(
  '[favorite] remove favorite failed',
  props<Action<{ error: Error }>>(),
);
