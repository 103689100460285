import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'sl-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavigationComponent implements OnInit {
  @HostBinding('class') hostClassList = 'd-flex h-100 w-100 align-items-center';

  hasNavigationBack$: Observable<boolean>;
  title$: Observable<string | null>;

  constructor(private navigationService: NavigationService) {
    this.hasNavigationBack$ = this.navigationService.navigationHistory$.pipe(map((list) => list.length > 1));
    this.title$ = this.navigationService.title$;
  }

  ngOnInit(): void {}

  goBack() {
    this.navigationService.goBack();
  }

  logout() {
    this.navigationService.logout();
  }
}
