import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  endpoint = '';
  uploadPath = '';

  constructor() {
    const { endpoint, uploadPath } = environment;
    this.endpoint = endpoint;
    this.uploadPath = uploadPath;
  }
}
