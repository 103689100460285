import { createReducer, on } from '@ngrx/store';
import { imageAdd, imageRemove, imageReplace } from '../actions/image.action';

export const IMAGE_STORE_KEY = 'images';

export interface ImagesState {
  [key: string]: any | null;
}

export const initialState: ImagesState = {};

export const imageReducer = createReducer(
  initialState,
  on(imageAdd, (state, { payload: { image } }): ImagesState => {
    if (!image.id) {
      return state;
    }
    return { ...state, [image.id]: image };
  }),
  on(imageRemove, (state, { payload: { id } }): ImagesState => {
    if (!state[id]) {
      return state;
    }
    const { [id]: value, ...stateWithoutItem } = state;
    return stateWithoutItem;
  }),
  on(imageReplace, (state, { payload: { images } }): ImagesState => {
    if (!images || images.length <= 0) {
      return state;
    }
    const replaceImages: ImagesState = {};
    for (const file of images) {
      const fileId: string = file.id;
      replaceImages[fileId] = file;
    }
    return { ...state, ...replaceImages };
  }),
);
