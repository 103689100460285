import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { imageload } from '../store/actions/image.action';
import { ImagesState } from '../store/reducers/image.reducer';
import { selectImagesState } from '../store/selectors/image.selectors';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  readonly images$: Observable<ImagesState>;

  constructor(private store: Store) {
    this.images$ = this.store.select(selectImagesState);
  }

  getImage(id: string) {
    return this.images$.pipe(
      map((images) => images[id]),
      distinctUntilChanged(),
      tap((image) => {
        if (!image) {
          this.loadImage(id);
        }
      }),
    );
  }

  loadImage(id: string) {
    this.store.dispatch(imageload({ payload: { id } }));
  }
}
