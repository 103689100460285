import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpotCreateRequestDto, SpotResponse } from '../../../../core/api/api';
import { PaginatedResponse } from '../../models/paginated.interface';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class SpotConnector {
  spotEndpoint: string;

  constructor(configService: ConfigService, private httpClient: HttpClient) {
    this.spotEndpoint = `${configService.endpoint}/spot`;
  }

  createSpot(createSpotDto: SpotCreateRequestDto): Observable<SpotResponse> {
    return this.httpClient.post<SpotResponse>(`${this.spotEndpoint}`, createSpotDto);
  }

  updateSpot(spotId: string, spotUpdateDto: any): Observable<SpotResponse> {
    return this.httpClient.post<SpotResponse>(`${this.spotEndpoint}/${spotId}`, spotUpdateDto);
  }

  loadSpot(spotId: string): Observable<SpotResponse> {
    return this.httpClient.get<SpotResponse>(`${this.spotEndpoint}/${spotId}`);
  }

  mySpots() {
    return this.httpClient.get<PaginatedResponse<SpotResponse>>(`${this.spotEndpoint}/me`);
  }

  loadAllSpots() {
    return this.httpClient.get<PaginatedResponse<SpotResponse>>(`${this.spotEndpoint}`);
  }
}
