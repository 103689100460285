import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FavoriteAddRequestDto, FavoriteRemoveRequestDto, FavoriteResponse } from '../../../core/api/api';
import { PaginatedResponse } from '../../shared/models/paginated.interface';
import { ConfigService } from '../../shared/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class FavoriteConnector {
  favoriteEndpoint: string;

  constructor(configService: ConfigService, private httpClient: HttpClient) {
    this.favoriteEndpoint = `${configService.endpoint}/favorite`;
  }

  addFavorite(favoriteAddDto: FavoriteAddRequestDto) {
    return this.httpClient.post<FavoriteResponse>(`${this.favoriteEndpoint}`, favoriteAddDto);
  }

  removeFavorite(favoriteRemoveDto: FavoriteRemoveRequestDto) {
    return this.httpClient.post<boolean>(`${this.favoriteEndpoint}/remove`, favoriteRemoveDto);
  }

  myFavorites() {
    return this.httpClient.get<PaginatedResponse<FavoriteResponse>>(`${this.favoriteEndpoint}/me`);
  }
}
