import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @HostBinding('class.is-visible')
  @Input()
  showFooter: boolean | null = false;

  constructor() {}

  ngOnInit(): void {}
}
