import { Portal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'sl-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigationComponent implements OnInit {
  @HostBinding('class') hostClassList = '';

  rooteNavigation$: Observable<string[]>;
  navigationPortal$: Observable<Portal<any> | null>;

  constructor(private navigationService: NavigationService) {
    this.rooteNavigation$ = this.navigationService.rooteNavigation$;
    this.navigationPortal$ = this.navigationService.navigationPortal$;
  }

  ngOnInit(): void {}
}
