<header
  class="d-flex align-items-center justify-content-between w-100 py-2 px-4"
  [class.navigation-open]="isOpen"
  [class.shadow-sm]="isOpen"
  [class.mobile]="isMobile"
>
  <div *ngIf="!isOpen">
    <a [routerLink]="['/']" class="d-block"><img src="assets/spotliked-logo.svg" /></a>
  </div>

  <div *ngIf="isOpen" class="d-flex flex-grow-1 align-items-center justify-content-between h-100 ps-4">
    <sl-header-navigation></sl-header-navigation>
  </div>

  <ng-container *ngIf="!isOpen">
    <ng-content></ng-content>
  </ng-container>
</header>
