import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, lastValueFrom, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { MediaFileDto } from '../../../core/api/api';
import { ImageBucket } from '../models/image-bucket.type';
import { MediaDialogOption } from '../models/media-dialog-option.interface';
import {
  mediaManagerDialogClose,
  mediaManagerDialogOpen,
  mediaManagerStoreDissmissed,
  mediaManagerStoreOpen,
  mediaManagerStoreOptions,
  mediaManagerStorePostSelectedFiles,
  mediaManagerStorePreSelectedFiles,
  mediaManagerStoreSpotId,
} from '../store/actions/media-manager.actions';
import {
  selectMediaManagerStateDissmissed,
  selectMediaManagerStateIsOpen,
  selectMediaManagerStateOption,
  selectMediaManagerStatePostSelectedFiles,
  selectMediaManagerStatePreSelectedFiles,
  selectMediaManagerStateSpotId,
} from '../store/selectors/media-manager.selectors';

@Injectable({
  providedIn: 'root',
})
export class MediaManagerService {
  readonly isOpen$: Observable<boolean>;
  readonly spotId$: Observable<string | null>;
  readonly option$: Observable<MediaDialogOption>;
  readonly dissmissed$: Observable<boolean>;
  readonly preSelectedFiles$: Observable<{ id: string }[]>;
  readonly postSelectedFiles$: Observable<MediaFileDto[]>;
  readonly onClose$: Observable<{ dissmissed: boolean; selectedFiles: MediaFileDto[] }>;

  constructor(private store: Store) {
    this.isOpen$ = this.store.select(selectMediaManagerStateIsOpen);
    this.spotId$ = this.store.select(selectMediaManagerStateSpotId);
    this.option$ = this.store.select(selectMediaManagerStateOption);
    this.dissmissed$ = this.store.select(selectMediaManagerStateDissmissed);
    this.preSelectedFiles$ = this.store.select(selectMediaManagerStatePreSelectedFiles);
    this.postSelectedFiles$ = this.store.select(selectMediaManagerStatePostSelectedFiles);

    this.onClose$ = combineLatest([this.isOpen$, this.dissmissed$, this.postSelectedFiles$]).pipe(
      filter(([isOpen]) => !isOpen),
      map(([isOpen, dissmissed, selectedFiles]) =>
        dissmissed ? { dissmissed: true, selectedFiles: [] } : { dissmissed, selectedFiles },
      ),
    );
  }

  openDialog(spotId?: string) {
    this.store.dispatch(mediaManagerDialogOpen({ payload: { spotId } }));
    return this.onClose$.pipe(take(1));
  }

  async openDialogHelper(spotId: string, imageBucket: ImageBucket) {
    const option: MediaDialogOption = {
      imageBucket,
      disableSwitchBucket: true,
      multiSelect: false,
    };
    this.storeOptions(option);
    this.storePreSelectedFiles([]);
    const data = await lastValueFrom(this.openDialog(spotId));
    let selectedFile = undefined;
    if (!data.dissmissed) {
      if (data.selectedFiles && data.selectedFiles.length > 0) {
        selectedFile = data.selectedFiles[0].id;
      }
    }
    return selectedFile;
  }

  closeMedia() {
    this.store.dispatch(mediaManagerDialogClose());
  }

  storeDialogOpen() {
    this.store.dispatch(mediaManagerStoreOpen({ payload: { isOpen: true } }));
  }

  storeDialogClose() {
    this.store.dispatch(mediaManagerStoreOpen({ payload: { isOpen: false } }));
  }

  storeSpotId(spotId: string | null) {
    this.store.dispatch(mediaManagerStoreSpotId({ payload: { spotId } }));
  }

  storeDissmissed(dissmissed: boolean) {
    this.store.dispatch(mediaManagerStoreDissmissed({ payload: { dissmissed } }));
  }

  storePreSelectedFiles(preSelectedFiles: { id: string }[]) {
    this.store.dispatch(mediaManagerStorePreSelectedFiles({ payload: { preSelectedFiles } }));
  }

  storePostSelectedFiles(postSelectedFiles: MediaFileDto[]) {
    this.store.dispatch(mediaManagerStorePostSelectedFiles({ payload: { postSelectedFiles } }));
  }

  storeOptions(option: MediaDialogOption) {
    this.store.dispatch(mediaManagerStoreOptions({ payload: { option } }));
  }
}
