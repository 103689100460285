<div class="action-container text-start">
  <button mat-button *ngIf="hasNavigationBack$ | async" (click)="goBack()">
    <mat-icon>arrow_back_ios</mat-icon>Zurück
  </button>
</div>
<div class="flex-grow-1 text-center position-relative w-100 h-100">
  <div class="position-absolute d-flex align-items-center w-100 h-100">
    <div class="header-title fs-5 w-100 text-center text-nowrap overflow-hidden">{{ title$ | async }}</div>
  </div>
</div>
<div class="action-container text-end">
  <button mat-button color="primary" (click)="logout()">Logout</button>
</div>
