import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { buffer, debounceTime, exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { ImageConnector } from '../../../media/services/image.connector';
import { ImageService } from '../../services/image.service';
import { imageAdd, imageload, imageReplace } from '../actions/image.action';

@Injectable()
export class ImageEffects {
  private loadNextTrigger$ = new Subject();

  imageCheckLoadNeeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(imageAdd),
        withLatestFrom(this.imageService.images$),
        filter(
          ([
            {
              payload: { image },
            },
            images,
          ]) => images[image.id],
        ),
        map(
          ([
            {
              payload: { image },
            },
            images,
          ]) => this.imageService.loadImage(image.id),
        ),
      ),
    { dispatch: false },
  );

  imageLoadChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(imageload),
      map((action) => action.payload.id as string),
      buffer(this.loadNextTrigger$),
      exhaustMap((images) => this.imageConnector.loadImages(images)),
      map((images) => imageReplace({ payload: { images } })),
    ),
  );

  imageLoadChunkTrigger$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(imageload),
        debounceTime(100),
        tap(() => this.loadNextTrigger$.next(null)),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private imageConnector: ImageConnector, private imageService: ImageService) {}
}
