import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { InboxConnector } from '../../services/connectors/inbox.connector';
import { InboxManagerService } from '../../services/inbox-manager.service';
import {
  inboxManagerLoadInboxFailed,
  inboxManagerLoadInboxSucceed,
  inboxManagerResetUnreadedMessageBegin,
  inboxManagerResetUnreadedMessageFailed,
  inboxManagerResetUnreadedMessageSuccess,
  inboxManagerUnset,
} from '../actions/inbox-manager.action';
import { tokenSet, tokenUnset, tokenValid } from '../actions/token.actions';

@Injectable()
export class InboxManagerEffects {
  unsetInbox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenUnset),
      map(() => inboxManagerUnset()),
    ),
  );

  loadInbox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenSet, tokenValid),
      exhaustMap((action) =>
        this.inboxConnector.myInbox().pipe(
          map((inbox) => inboxManagerLoadInboxSucceed({ payload: { inbox } })),
          catchError(({ error }) => of(inboxManagerLoadInboxFailed({ payload: { error } }))),
        ),
      ),
    ),
  );

  resetUnreadedMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inboxManagerResetUnreadedMessageBegin),
      withLatestFrom(this.inboxManagerService.unreadedMessages$),
      filter(([action, unreadedMessages]) => unreadedMessages),
      exhaustMap(([action]) =>
        this.inboxConnector.resetUnreadedMessages().pipe(
          map((inbox) => inboxManagerResetUnreadedMessageSuccess({ payload: { inbox } })),
          catchError(({ error }) => of(inboxManagerResetUnreadedMessageFailed({ payload: { error } }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private inboxManagerService: InboxManagerService,
    private inboxConnector: InboxConnector,
  ) {}
}
