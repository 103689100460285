import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SpotResponse } from '../../../core/api/api';
import { spotSetSelectedSpot, spotUnsetSelectedSpot } from '../store/actions/spot.action';
import { selectSelectedSpot } from '../store/selectors/spot.selectors';

@Injectable({
  providedIn: 'root',
})
export class SpotService {
  selectedSpot$: Observable<SpotResponse | null>;

  constructor(private store: Store) {
    this.selectedSpot$ = this.store.select(selectSelectedSpot);
  }

  setSelectedSpot(spotId: string) {
    this.store.dispatch(spotSetSelectedSpot({ payload: { spotId } }));
  }

  unsetSelectedSpot() {
    this.store.dispatch(spotUnsetSelectedSpot());
  }
}
