import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DealResponse } from '../../../core/api/api';
import { dealSetSelectedDeal, dealUnsetSelectedDeal } from '../store/actions/deal.action';
import { selectSelectedDeal } from '../store/selectors/deal.selectors';

@Injectable({
  providedIn: 'root',
})
export class DealService {
  readonly selectedDeal$: Observable<DealResponse | null>;

  constructor(private store: Store) {
    this.selectedDeal$ = this.store.select(selectSelectedDeal);
  }

  setSelectedDeal(dealId: string) {
    this.store.dispatch(dealSetSelectedDeal({ payload: { dealId } }));
  }

  unsetSelectedDeal() {
    this.store.dispatch(dealUnsetSelectedDeal());
  }
}
