import { createReducer, on } from '@ngrx/store';
import { tokenExpired, tokenSet, tokenUnset, tokenValid } from '../actions/token.actions';

export const TOKEN_STORE_KEY = 'access_token';

export interface TokenState {
  readonly accessToken: string | null;
}

export const initialState: TokenState = {
  accessToken: null,
};

export const tokenReducer = createReducer(
  initialState,
  on(tokenSet, (state, action): TokenState => ({ ...state, accessToken: action.payload.accessToken })),
  on(tokenValid, (state, action): TokenState => ({ ...state, accessToken: action.payload.accessToken })),
  on(tokenUnset, (state, action): TokenState => initialState),
  on(tokenExpired, (state, action): TokenState => initialState),
);
