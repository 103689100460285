import { createReducer, on } from '@ngrx/store';
import { userSet, userUnset } from '../actions/user.actions';

export const USER_STORE_KEY = 'user';

export enum Role {
  influencer = 'influencer',
  owner = 'owner',
  admin = 'admin',
}

export interface UserState {
  readonly id: string | null;
  readonly roles: Role[] | null;
}

export const initialState: UserState = {
  id: null,
  roles: null,
};

export const userReducer = createReducer(
  initialState,
  on(userSet, (state, action): UserState => ({ ...state, ...action.payload.user })),
  on(userUnset, (state, action): UserState => initialState),
);
