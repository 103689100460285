import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class NotificationEffects {
  showNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        filter((action) => !!(action as any).payload?.error),
        tap((action) => {
          const error: Error = (action as any).payload.error;
          this.matSnackBar.open(error.message, 'OK', {
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            duration: 3000,
          });
        }),
      ),
    {
      dispatch: false,
    },
  );

  constructor(private actions$: Actions, private matSnackBar: MatSnackBar) {}
}
