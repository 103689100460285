import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { SpotConnector } from '../../services/connectors/spot.connector';
import { SpotService } from '../../services/spot.service';
import { spotLoadSpotFailed, spotLoadSpotSucceed, spotSetSelectedSpot } from '../actions/spot.action';

@Injectable()
export class SpotEffects {
  loadSpot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spotSetSelectedSpot),
      exhaustMap((action) =>
        this.spotConnector.loadSpot(action.payload.spotId).pipe(
          map((data) => spotLoadSpotSucceed({ payload: { spot: data } })),
          catchError(({ error }) => of(spotLoadSpotFailed({ payload: { error } }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private spotConnector: SpotConnector, private spotService: SpotService) {}
}
