import { createAction, props } from '@ngrx/store';
import { MediaFileDto } from '../../../../core/api/api';
import { ImageBucket } from '../../../shared/models/image-bucket.type';
import { PaginatedResponse } from '../../../shared/models/paginated.interface';
import { Action } from '../../../shared/store/actions/base.action';

export const mediaAddSelectedFile = createAction(
  '[media] add Selected file',
  props<Action<{ selectedFile: { id: string } }>>(),
);
export const mediaRemoveSelectedFile = createAction(
  '[media] remove Selected file',
  props<Action<{ selectedFile: { id: string } }>>(),
);
export const mediaSetSelectedFiles = createAction(
  '[media] set Selected file',
  props<Action<{ selectedFiles: { id: string }[] }>>(),
);

export const mediaStoreReset = createAction('[media] reset store');
export const mediaSetImageBucket = createAction(
  '[media] set ImageBucket',
  props<Action<{ imageBucket: ImageBucket }>>(),
);

export const mediaLoadBegin = createAction('[media] load media files trigger');
export const mediaLoadSucceed = createAction(
  '[media] load media files succeed',
  props<Action<PaginatedResponse<MediaFileDto>>>(),
);
export const mediaLoadError = createAction('[media] load media files error');

export const mediaUploadBegin = createAction(
  '[media] upload media files trigger',
  props<Action<{ file: File; crop?: any }>>(),
);
export const mediaUploadSucceed = createAction(
  '[media] upload media files succeed',
  props<Action<{ file: MediaFileDto }>>(),
);
export const mediaUploadError = createAction('[media] upload media files error');

export const mediaCloseModalDialog = createAction('[media] flush Data', props<Action<{ dissmiss: boolean }>>());
