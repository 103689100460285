import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationModule } from './navigation/navigation.module';
import { NotificationModule } from './notification/notification.module';
import { DealEffects } from './shared/store/effects/deal.effects';
import { FavoriteEffects } from './shared/store/effects/favorite.effects';
import { ImageEffects } from './shared/store/effects/image.effects';
import { InboxManagerEffects } from './shared/store/effects/inbox-manager.effects';
import { MediaRoutingEffects } from './shared/store/effects/media-routing.effects';
import { SpotEffects } from './shared/store/effects/spot.effects';
import { TokenRedirectEffects } from './shared/store/effects/token-redirect.effects';
import { TokenEffects } from './shared/store/effects/token.effects';
import { metaReducers, reducers } from './store.module';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NavigationModule,
    HttpClientModule,
    NotificationModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.endpoint.replace('https://', ''), environment.endpoint.replace('http://', '')],
      },
    }),
    EffectsModule.forRoot([
      TokenEffects,
      TokenRedirectEffects,
      MediaRoutingEffects,
      ImageEffects,
      SpotEffects,
      DealEffects,
      InboxManagerEffects,
      FavoriteEffects,
    ]),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production , connectInZone: true}) : [],
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE', // 'de-DE' for Germany,
    },
    {
      provide: Window,
      useValue: window,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
