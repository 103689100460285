import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, tap } from 'rxjs';
import { MediaService } from '../../media/services/media.service';
import { MediaManagerService } from './media-manager.service';

@Injectable({
  providedIn: 'root',
})
export class MediaRoutingService {
  constructor(
    private router: Router,
    private mediaManagerService: MediaManagerService,
    private mediaService: MediaService,
  ) {}

  openMedia() {
    // this.router.navigate([{ outlets: { modal: ['media'] } }]);
    this.mediaService.storeReset();
    this.mediaManagerService.storeDialogOpen();
  }

  openSpotMedia(spotId: string) {
    // this.router.navigate([{ outlets: { modal: ['media', 'spot', spotId] } }]);
    combineLatest([this.mediaService.option$, this.mediaService.preSelectedFiles$]).pipe(
      tap(([option, preSelectedFiles]) => {
        this.mediaService.storeImageBucket(option.imageBucket);
        this.mediaService.setSelectFile(preSelectedFiles);
      }),
    );
    this.mediaManagerService.storeSpotId(spotId);
    this.mediaManagerService.storeDialogOpen();
  }

  closeMedia() {
    // this.router.navigate([{ outlets: { modal: [] } }]);
    this.mediaManagerService.storeSpotId(null);
    this.mediaManagerService.storeDialogClose();
  }
}
