import { Portal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'sl-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavigationComponent implements OnInit {
  navigationMobilePortal$: Observable<Portal<any> | null>;

  constructor(private navigationService: NavigationService) {
    this.navigationMobilePortal$ = this.navigationService.navigationMobilePortal$;
  }

  ngOnInit(): void {}
}
