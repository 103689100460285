import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { MediaFileDto } from '../../../core/api/api';
import { ImageBucket } from '../../shared/models/image-bucket.type';
import { MediaDialogOption } from '../../shared/models/media-dialog-option.interface';
import { MediaManagerService } from '../../shared/services/media-manager.service';
import { selectUserId } from '../../shared/store/selectors/user.selectors';
import {
  mediaAddSelectedFile,
  mediaCloseModalDialog,
  mediaLoadBegin,
  mediaRemoveSelectedFile,
  mediaSetImageBucket,
  mediaSetSelectedFiles,
  mediaStoreReset,
  mediaUploadBegin,
} from '../store/actions/media.actions';
import {
  selectMediaStateFiles,
  selectMediaStateImageBucket,
  selectMediaStateSelectedFiles,
} from '../store/selectors/media.selectors';

export type MediaFileDtoWithSelected = MediaFileDto & { selected: boolean };

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  readonly userId$: Observable<string | null>;
  readonly mediaStateSpotId$: Observable<string | null>;
  readonly option$: Observable<MediaDialogOption>;
  readonly files$: Observable<{ id: string }[]>;
  readonly filesWithSelectedFlag$: Observable<MediaFileDtoWithSelected[]>;
  readonly selectedFiles$: Observable<MediaFileDto[]>;
  readonly preSelectedFiles$: Observable<{ id: string }[]>;
  readonly mediaStateImageBucket$: Observable<ImageBucket>;
  readonly relatedId$: Observable<string>;

  constructor(private store: Store, private mediaManagerService: MediaManagerService) {
    this.userId$ = this.store.select(selectUserId);
    this.mediaStateSpotId$ = this.mediaManagerService.spotId$;
    this.preSelectedFiles$ = this.mediaManagerService.preSelectedFiles$;
    this.option$ = this.mediaManagerService.option$;
    this.files$ = this.store.select(selectMediaStateFiles);
    this.mediaStateImageBucket$ = this.store.select(selectMediaStateImageBucket);

    this.relatedId$ = combineLatest([this.userId$, this.mediaStateSpotId$]).pipe(
      map(([userId, spotId]) => (spotId ? spotId : userId!)),
    );

    const mediaStateSelectedFiles$ = this.store.select(selectMediaStateSelectedFiles);
    this.filesWithSelectedFlag$ = mediaStateSelectedFiles$.pipe(
      map((fileList: { id: string }[]) => new Set(fileList.map((f) => f.id))),
      switchMap((fileSet) =>
        this.files$.pipe(
          filter((loadedFiles) => !!loadedFiles),
          map((loadedFiles) =>
            loadedFiles.map(
              (file) => ({ ...file, selected: fileSet.has(file.id) } as MediaFileDto & { selected: boolean }),
            ),
          ),
        ),
      ),
    );
    this.selectedFiles$ = this.filesWithSelectedFlag$.pipe(map((files) => files.filter((f) => f.selected)));
  }

  storeReset() {
    this.store.dispatch(mediaStoreReset());
  }

  mediaLoadBegin() {
    this.store.dispatch(mediaLoadBegin());
  }

  storeSpotId(spotId: string) {
    this.mediaManagerService.openDialog(spotId);
  }

  storeImageBucket(imageBucket: ImageBucket) {
    this.store.dispatch(mediaSetImageBucket({ payload: { imageBucket } }));
  }

  addSelectFile(selectedFile: { id: string }) {
    this.store.dispatch(mediaAddSelectedFile({ payload: { selectedFile } }));
  }

  removeSelectFile(selectedFile: { id: string }) {
    this.store.dispatch(mediaRemoveSelectedFile({ payload: { selectedFile } }));
  }

  setSelectFile(selectedFiles: { id: string }[]) {
    this.store.dispatch(mediaSetSelectedFiles({ payload: { selectedFiles } }));
  }

  closeDialog(dissmiss: boolean) {
    this.store.dispatch(mediaCloseModalDialog({ payload: { dissmiss } }));
  }

  uploadFile(file: File, crop?: any) {
    this.store.dispatch(mediaUploadBegin({ payload: { file, crop } }));
  }
}
