import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { FavoriteConnector } from '../../../influencer/services/favorite.connector';
import {
  favoriteAddBegin,
  favoriteAddFailed,
  favoriteAddSucceed,
  favoriteRemoveBegin,
  favoriteRemoveFailed,
  favoriteRemoveSucceed,
} from '../actions/favorite.action';

@Injectable()
export class FavoriteEffects {
  favoriteAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(favoriteAddBegin),
      exhaustMap((action) =>
        this.favoriteConnector.addFavorite(action.payload.favoriteAddDto).pipe(
          map((data) => favoriteAddSucceed({ payload: { api_response: data } })),
          catchError((error) => of(favoriteAddFailed({ payload: { error } }))),
        ),
      ),
    ),
  );

  favoriteRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(favoriteRemoveBegin),
      exhaustMap((action) =>
        this.favoriteConnector.removeFavorite(action.payload.favoriteRemoveDto).pipe(
          map(() => favoriteRemoveSucceed()),
          catchError((error) => of(favoriteRemoveFailed({ payload: { error } }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private favoriteConnector: FavoriteConnector) {}
}
