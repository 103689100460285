import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InboxResponse } from '../../../../core/api/api';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class InboxConnector {
  inboxEndpoint: string;

  constructor(configService: ConfigService, private httpClient: HttpClient) {
    this.inboxEndpoint = `${configService.endpoint}/inbox`;
  }

  loadInfluencerInbox(influencerId: string): Observable<InboxResponse> {
    return this.httpClient.get<InboxResponse>(`${this.inboxEndpoint}/influencer/${influencerId}`);
  }

  myInbox() {
    return this.httpClient.get<InboxResponse>(`${this.inboxEndpoint}/me`);
  }

  resetUnreadedMessages() {
    return this.httpClient.post<InboxResponse>(`${this.inboxEndpoint}/me/reset-unreaded`, {});
  }
}
