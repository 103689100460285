import { createReducer, on } from '@ngrx/store';
import { MediaFileDto } from '../../../../core/api/api';
import { ImageBucket } from '../../models/image-bucket.type';
import { MediaDialogOption } from '../../models/media-dialog-option.interface';
import {
  mediaManagerStoreDissmissed,
  mediaManagerStoreOpen,
  mediaManagerStoreOptions,
  mediaManagerStorePostSelectedFiles,
  mediaManagerStorePreSelectedFiles,
  mediaManagerStoreReset,
  mediaManagerStoreSpotId,
} from '../actions/media-manager.actions';

export const MEDIA_MANAGER_STORE_KEY = 'mediaManager';

export interface MediaManagerState {
  readonly isOpen: boolean;
  readonly spotId: string | null;
  readonly preSelectedFiles: { id: string }[];
  readonly postSelectedFiles: MediaFileDto[];
  readonly dissmissed: boolean;
  readonly option: MediaDialogOption;
}

export const initialState: MediaManagerState = {
  isOpen: false,
  spotId: null,
  preSelectedFiles: [],
  postSelectedFiles: [],
  dissmissed: true,
  option: {
    imageBucket: ImageBucket.deal_gallery,
    multiSelect: true,
    disableSwitchBucket: false,
  },
};

export const mediaManagerReducer = createReducer(
  initialState,
  on(mediaManagerStoreReset, (state): MediaManagerState => initialState),
  on(mediaManagerStoreOpen, (state, { payload: { isOpen } }): MediaManagerState => {
    if (state.isOpen === isOpen) {
      return state;
    }
    return { ...state, isOpen };
  }),
  on(mediaManagerStoreSpotId, (state, { payload: { spotId } }): MediaManagerState => {
    if (state.spotId === spotId) {
      return state;
    }
    return { ...state, spotId };
  }),
  on(mediaManagerStorePreSelectedFiles, (state, { payload: { preSelectedFiles } }): MediaManagerState => {
    if (state.preSelectedFiles === preSelectedFiles) {
      return state;
    }
    return { ...state, preSelectedFiles };
  }),
  on(mediaManagerStorePostSelectedFiles, (state, { payload: { postSelectedFiles } }): MediaManagerState => {
    if (state.postSelectedFiles === postSelectedFiles) {
      return state;
    }
    return { ...state, postSelectedFiles };
  }),
  on(mediaManagerStoreDissmissed, (state, { payload: { dissmissed } }): MediaManagerState => {
    if (state.dissmissed === dissmissed) {
      return state;
    }
    return { ...state, dissmissed };
  }),
  on(mediaManagerStoreOptions, (state, { payload: { option } }): MediaManagerState => {
    if (state.option === option) {
      return state;
    }
    return { ...state, option: { ...option } };
  }),
);
