import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavigationState, NAVIGATION_STORE_KEY } from '../redurcers/navigation.reducer';

export const selectNavigationState = createFeatureSelector<NavigationState>(NAVIGATION_STORE_KEY);
export const selectNavigationIsOpen = createSelector(selectNavigationState, (navigation) => navigation.isOpen);
export const selectNavigationRootNavigation = createSelector(
  selectNavigationState,
  (navigation) => navigation.rootNavigation,
);
export const selectNavigationHistory = createSelector(
  selectNavigationState,
  (navigation) => navigation.navigationHistory,
);
