import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GalleryDto, MediaFileDto } from '../../../core/api/api';
import { ImageBucket } from '../../shared/models/image-bucket.type';
import { PaginatedResponse } from '../../shared/models/paginated.interface';
import { ConfigService } from '../../shared/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class ImageConnector {
  imageEndpoint: string;
  imageSpotEndpoint: string;

  constructor(configService: ConfigService, private httpClient: HttpClient) {
    this.imageEndpoint = `${configService.endpoint}/image`;
    this.imageSpotEndpoint = `${this.imageEndpoint}/spot`;
  }

  deleteSpotFiles(spotId: string, fileIds: string[]): Observable<any> {
    return this.httpClient.delete<any>(`${this.imageEndpoint}/spot/${spotId}`, { body: { fileIds } });
  }

  loadImages(images: string[]) {
    return this.httpClient.post<GalleryDto>(`${this.imageEndpoint}/images`, { images });
  }

  spotImages(spotId: string, imageBucket?: ImageBucket) {
    let params = {};
    if (imageBucket) {
      params = {
        imageBucket,
      };
    }
    return this.httpClient.get<PaginatedResponse<MediaFileDto>>(`${this.imageSpotEndpoint}/${spotId}`, { params });
  }

  myImage() {
    return this.httpClient.get<PaginatedResponse<MediaFileDto>>(`${this.imageEndpoint}/me`);
  }

  uploadImage(imageBucket: ImageBucket, image: any): Observable<MediaFileDto> {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('imageBucket', imageBucket);
    return this.httpClient.post<MediaFileDto>(`${this.imageEndpoint}`, formData);
  }

  uploadFileForSpot(spotId: string, imageBucket: ImageBucket, image: any, crop?: any): Observable<MediaFileDto> {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('imageBucket', imageBucket);
    if (crop) {
      formData.append('crop', JSON.stringify(crop));
    }
    return this.httpClient.post<MediaFileDto>(`${this.imageSpotEndpoint}/${spotId}`, formData);
  }
}
