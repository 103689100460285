import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderNavigationComponent } from './containers/header-navigation/header-navigation.component';
import { MainNavigationComponent } from './containers/main-navigation/main-navigation.component';
import { NavigationMenuComponent } from './containers/navigation-menu/navigation-menu.component';
import { NavigationComponent } from './containers/navigation/navigation.component';
import { TabNavigationComponent } from './containers/tab-navigation/tab-navigation.component';
import { NavigationEffects } from './store/effects/navigation.effects';
import { navigationReducer, NAVIGATION_STORE_KEY } from './store/redurcers/navigation.reducer';

@NgModule({
  declarations: [
    NavigationComponent,
    HeaderComponent,
    FooterComponent,
    MainNavigationComponent,
    HeaderNavigationComponent,
    TabNavigationComponent,
    NavigationMenuComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    StoreModule.forFeature(NAVIGATION_STORE_KEY, navigationReducer),
    EffectsModule.forFeature([NavigationEffects]),
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {}
