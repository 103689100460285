import { createAction, props } from '@ngrx/store';
import { InboxResponse } from '../../../../core/api/api';
import { Action } from './base.action';

export const inboxManagerUnset = createAction('[inbox manager] unset inbox');

export const inboxManagerLoadInboxSucceed = createAction(
  '[inbox manager] load Inbox succeed',
  props<Action<{ inbox: InboxResponse }>>(),
);
export const inboxManagerLoadInboxFailed = createAction(
  '[inbox manager] load Inbox failed',
  props<Action<{ error: Error }>>(),
);

export const inboxManagerResetUnreadedMessageBegin = createAction('[inbox manager] reset unreadded messages trigger');
export const inboxManagerResetUnreadedMessageSuccess = createAction(
  '[inbox manager] reset unreadded messages succeed',
  props<Action<{ inbox: InboxResponse }>>(),
);
export const inboxManagerResetUnreadedMessageFailed = createAction(
  '[inbox manager] reset unreadded messages failed',
  props<Action<{ error: Error }>>(),
);
